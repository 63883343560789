@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.rukojudul {
  width: 100%;
  @media (max-width: 768px) {
    width: 98%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardrumahhome1 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      width: 98%;
    }
    .card {
      margin-right: 50px;
      background-color: #14345c;
      border-radius: 5px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
      width: 400px;
      margin-bottom: 25px;
      height: auto;
      @media (max-width: 768px) {
        width: 98%;
        margin-bottom: 25px;
        margin-right: 0px;
      }
      .imgcarousel {
        width: 100%;
        height: auto;
        border-radius: 5px 5px 0px 0px;
      }
      .container-title-card {
        width: 100%;
        margin-top: 0px;
        display: flex;
        justify-content: center;

        .titlecard {
          width: 100%;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .judul-rumah1 {
            padding-left: 10px;
            color: white;
            font-family: "Poppins";
            font-size: 30px;
            font-weight: 600;
            align-self: flex-start;
          }
          .sub-judul-rumah {
            color: white;
            font-size: 16px;
            font-weight: 400;
            margin-left: 10px;
            margin-bottom: 5px;
            align-self: flex-start;
          }
        }
        .gridspek {
          width: 100%;
          padding-left: 20px;
          display: flex;
          justify-content: start;
          align-items: center;
          border-radius: 5px;
          color: white;
          @media (max-width: 768px) {
            padding: 15px 0px;
          }
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 0px;
            padding-right: 20px;
            @media (max-width: 768px) {
              padding-right: 20px;
              font-size: 14px;
            }
          }
        }
        .cicilan {
          margin-top: 10px;
          padding: 10px;
          width: 100%;
          background-color: white;
          //padding: 10px;
          border-radius: 0 0px 10px 10px;
          color: #14345c;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          .start {
            padding-left: 10px;
            width: 100%;
            flex-direction: row;
            justify-content: left;
            font-family: "Roboto";
            font-weight: 600;
            font-size: 24px;
            @media (max-width: 768px) {
              font-size: 20px;
            }
          }
          .det {
            flex-direction: column;
            width: 100%;
            .angka {
              font-family: "Roboto";
              font-weight: 600;
              font-size: 53px;
              text-align: center;
            }
            .ket {
              font-family: "Roboto";
              font-weight: 600;
              font-size: 16px;
              text-align: center;
            }
          }
        }
      }
      .container-button {
        display: flex;
        justify-content: center;
        align-items: center;
        .containerwhatsapp {
          width: 100%;
          padding-top: 8px;
          padding-bottom: 10px;
          display: flex;
          justify-content: center;
          .whatsapp {
            //  display: inline-block;
            padding: 15px 95px;
            cursor: pointer;
            border: none;
            border-radius: 5px;
            background-color: #61ce70; /* WhatsApp green */
            color: white;
            font-size: 14px;
          }
        }
      }
    }
  }
}
