.containerruko {
  margin-top: 0px;
  background-color: #1c244b;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
  .commercial {
    width: 80%;
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column;
    }
    .slider-com {
      width: 50%;
      @media (max-width: 768px) {
        width: 100%;
        order: 2;
      }
      .slick-slider {
        width: 100%;
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
        }
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }

        .slick-dots {
          position: absolute; /* Make dots absolute for positioning within slides */ /* Adjust vertical positioning */
          left: 50%; /* Center dots horizontally */
          transform: translateX(-50%); /* Center dots precisely */
          z-index: 1; /* Ensure dots are above image content */
        }
        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
        .slick-dots {
          li {
            margin: 0;
          }
        }
      }
    }
    .ket-commercial {
      width: 50%;
      margin-left: 50px;
      display: flex;
      justify-content: center;
      align-items: start;
      flex-direction: column;
      @media (max-width: 768px) {
        width: 98%;
        order: 1;
        align-items: center;
        margin-left: 0px;
        margin-bottom: 20px;
      }
      .judul-commercial {
        font-family: "Poppins";
        font-size: 65px;
        font-weight: 650;
        line-height: 1.2em;
        color: white;
        @media (max-width: 768px) {
          width: 98%;
          font-size: 28px;
          text-align: center;
        }
      }
      .subjudul-comm {
        margin-top: 10px;
        font-family: "Poppins";
        font-size: 22px;
        font-weight: 300;
        line-height: 1.5em;
        color: #bcbcbc;
        @media (max-width: 768px) {
          width: 98%;
          font-size: 20px;
          text-align: center;
        }
      }
      .cicilan-comm {
        font-family: "Poppins";
        font-size: 17px;
        font-weight: 300;
        line-height: 1.5em;
        color: #bcbcbc;
        @media (max-width: 768px) {
          width: 100%;
          font-size: 20px;
          text-align: center;
        }
      }
      .spek-comm {
        margin-top: 10px;
        color: white;
      }
      .button-comm {
        margin-top: 15px;
        .button {
          padding: 16px 55px;
          border-radius: 100px;
          background-color: #467ff7;
          border-style: solid;
          border-width: 1px;
          border-color: #467ff7;
          color: white;
          font-family: "Poppins";
          font-size: 16px;
          font-weight: 400;
          line-height: 1em;
          cursor: pointer;
          &:hover {
            border-color: #467ff7;
            background-color: #ffffff00;
            color: #467ff7;
          }
        }
      }
    }
  }
}
