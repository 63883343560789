@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.apartment {
  margin-top: 50px;
  background-color: #1c244b;
  padding-top: 5%;
  padding-bottom: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .container-apart {
    width: 80%;
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
      flex-direction: column;
      width: 98%;
    }
    .text-apart {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: start;
      flex-direction: column;
      @media (max-width: 768px) {
        width: 98%;
        align-items: center;
      }
      .judul-apart {
        font-family: "Poppins";
        font-size: 65px;
        font-weight: 650;
        line-height: 1.2em;
        color: white;
        @media (max-width: 768px) {
          width: 100%;
          font-size: 36px;
          text-align: center;
        }
      }
      .subjudul-apart {
        margin-top: 10px;
        font-family: "Poppins";
        font-size: 22px;
        font-weight: 300;
        line-height: 1.5em;
        color: #bcbcbc;
        @media (max-width: 768px) {
          width: 100%;
          font-size: 24px;
          text-align: center;
        }
      }
      .cicilan-apart {
        font-family: "Poppins";
        font-size: 17px;
        font-weight: 300;
        line-height: 1.5em;
        color: #bcbcbc;
        @media (max-width: 768px) {
          width: 100%;
          font-size: 24px;
          text-align: center;
        }
      }
      .spek-apart {
        margin-top: 10px;
        color: white;
        @media (max-width: 768px) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .button-apart {
        margin-top: 15px;
        .button {
          padding: 16px 55px;
          border-radius: 100px;
          background-color: #467ff7;
          border-style: solid;
          border-width: 1px;
          border-color: #467ff7;
          color: white;
          font-family: "Poppins";
          font-size: 16px;
          font-weight: 400;
          line-height: 1em;
          cursor: pointer;
          &:hover {
            border-color: #467ff7;
            background-color: #ffffff00;
            color: #467ff7;
          }
        }
      }
    }
    .carousel-apart {
      width: 50%;
      @media (max-width: 768px) {
        margin-top: 30px;
        width: 100%;
      }
      .slick-slider {
        width: 100%;
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
        }
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }

        .slick-dots {
          position: absolute; /* Make dots absolute for positioning within slides */ /* Adjust vertical positioning */
          left: 50%; /* Center dots horizontally */
          transform: translateX(-50%); /* Center dots precisely */
          z-index: 1; /* Ensure dots are above image content */
        }
        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
        .slick-dots {
          li {
            margin: 0;
          }
        }
      }
    }
  }
}
