.othercluster {
  .container-othercluster {
    .judul-section {
      font-size: 65px;
      font-family: "Poppins", sans-serif;
      color: #15283b;
      font-weight: 500;
      margin-top: 50px;
      text-align: center;
    }
    .container-card {
      display: flex;
      flex-direction: row;
      .cardhome-container {
        margin-top: 25px;
        width: 100%;
        display: grid;
        grid-template-columns: auto auto auto auto;
        justify-content: center;

        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}
