.promo {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 150px;
  }
  .container-promo {
    width: 100%;

    .container-promohead {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .judul-promo1 {
        font-size: 55px;
        font-family: "Poppins", sans-serif;
        color: #0d3276;
        @media (max-width: 768px) {
          font-size: 30px;
        }
      }
      .logo-venture {
        padding: 30px;
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
          flex-direction: column;
        }
        .logoventure1 {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .hongkongland {
            object-fit: cover;
            padding: 20px;
          }
        }
        .logoventure2 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          .sinarmasland {
            object-fit: cover;
            padding: 20px;
          }
        }
      }
    }
    .container-promobody {
      .container-listpromo {
        margin-top: 30px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-end; /* Added justify-content */
        @media (max-width: 768px) {
          flex-direction: column;
          margin-top: -15px;
        }
        .containergambar {
          order: 1;
          width: 100%;
          text-align: right;
          @media (max-width: 768px) {
            margin-top: 25px;
            order: 1;
            width: 100%;
            text-align: center;
          }

          .penawarangambar {
            width: 500px;
            height: 650px;
            align-self: flex-end;
            box-shadow: 0 0 10px 0;
            border-radius: 5px;
            @media (max-width: 768px) {
              width: 90%;
              height: 100%;
            }
          }
        }

        .containercontent {
          order: 2;
          width: 100%;
          margin-left: 100px;
          text-align: left;
          justify-content: center;
          display: flex;
          flex-direction: column;
          @media (max-width: 768px) {
            margin-left: 25px;
            width: 90%;
            order: 2;
            margin-top: 30px;
          }
        }
        .judul-promo {
          width: 60%;
          font-weight: 500;
          font-size: 40px;
          line-height: 1em;
          color: #292929;
          font-family: "Poppins";
          @media (max-width: 768px) {
            width: 100%;
            font-size: 25px;
            margin-bottom: 17px;
          }
        }
        .disclaimer {
          margin-top: 25px;
          font-size: 13px;
        }
        .pointpenawaran {
          flex-direction: row;
          font-size: 30px;
          @media (max-width: 768px) {
            font-size: 25px;
            width: 100%;
          }
          .penawaranpoin {
            margin-top: 0px;
            color: #273444;
            @media (max-width: 768px) {
              width: 100%;
            }
          }
        }
        .button-wa-promo {
          padding-top: 15px;
          padding-bottom: 10px;
          .whatsapp {
            display: inline-block;
            padding: 10px 100px;
            cursor: pointer;
            border-radius: 2px;
            border: solid;
            border-width: 1px;
            background-color: #f4a40400; /* WhatsApp green */
            color: rgb(0, 0, 0);
            font-size: 16px;
            @media (max-width: 768px) {
              padding: 15px 80px;
            }
            &:hover {
              background-color: #1b252f;
              color: white;
              padding: 15px 110px;
              transition-duration: 0.3s;
            }
          }
        }
      }
    }
  }
  .slider-fasilitas {
    margin-top: 80px;
    width: 80%;
    box-shadow: 0 0 5px 0;
    border-radius: 10px;
    .slick-slider {
      width: 100%;
      .slick-next::before,
      .slick-prev::before {
        font-family: "slick";
        font-size: 30px;
      }
      .slick-list {
        border-radius: 10px;
      }
      .slick-track {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
      }

      .slick-dots {
        position: absolute; /* Make dots absolute for positioning within slides */ /* Adjust vertical positioning */
        left: 50%; /* Center dots horizontally */
        transform: translateX(-50%); /* Center dots precisely */
        z-index: 1; /* Ensure dots are above image content */
      }
      .slick-next {
        right: 25px;
      }
      .slick-prev {
        z-index: 1;
        left: 25px;
      }
      .slick-dots {
        li {
          margin: 0;
        }
      }
    }
  }
  .devider-cluster {
    margin-top: 50px;
    background-color: #15283b;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .judul-cluster {
      width: 100%;
      text-align: center;
      color: white;
      font-weight: 400;
      font-size: 40px;
      line-height: 1em;
      font-family: "Poppins", sans-serif;
      @media (max-width: 768px) {
        font-size: 25px;
      }
    }
    .carousel-logocluster {
      width: 50%;
      display: flex;
      justify-content: center;
      @media (max-width: 768px) {
        width: 98%;
        margin-top: 10px;
      }
      .slick-slider {
        width: 70%;
        margin: auto;
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
        }
        .slick-list {
          border-radius: 10px;
        }
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }

        .slick-dots {
          position: absolute; /* Make dots absolute for positioning within slides */ /* Adjust vertical positioning */
          left: 50%;
          bottom: -15px; /* Center dots horizontally */
          transform: translateX(-50%); /* Center dots precisely */
          z-index: 1; /* Ensure dots are above image content */
        }
        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
        .slick-dots {
          li {
            margin: 0;
          }
        }
      }
    }
  }
}
