@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.rumah {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  .judul-cluster {
    font-size: 65px;
    font-family: "Poppins", sans-serif;
    color: #15283b;
    font-weight: 500;
    margin-top: 50px;
  }
  .a {
    display: flex;
    flex-direction: row;
    .cardhome12-container {
      margin-top: 25px;
      width: 100%;
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: center;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
