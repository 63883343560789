@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

.header {
  width: 100%;
  height: auto;
  .container-header {
    width: 100%;
    height: 20%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 768px) {
    }

    .judul-web {
      font-size: 20px;
      padding: 15px;
      color: #0d3276;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 500;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
  }
  .banner-header {
    margin-top: -5px;
    background-image: url("../../asset/header/header-dekstop.webp"); /* Replace with your desktop image URL */
    background-size: cover;
    background-position: center;
    height: 70vh;
    @media (max-width: 768px) {
      /* Adjust breakpoint as needed */
      background-image: url("../../asset/header/header-mobile.webp");
      width: 100%;
      margin-top: -7px;
    }
  }
  .divider {
    background-color: #5da4ce;
    height: 100px;
    overflow: hidden;
    position: relative;
    &:before {
      content: "";
      font-family: "shape divider from ShapeDividers.com";
      position: absolute;
      bottom: -1px;
      left: -1px;
      right: -1px;
      top: -1px;
      z-index: 3;
      pointer-events: none;
      background-repeat: no-repeat;
      background-size: 100% 92px;
      background-position: 50% 0%;
      background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23ffffff"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23ffffff"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23ffffff"/></svg>');
    }

    @media (min-width: 768px) {
      .shapedividers_com-5429::before {
        background-size: 100% 92px;
        background-position: 50% 0%;
      }
    }

    @media (min-width: 1025px) {
      .shapedividers_com-5429::before {
        bottom: -0.1vw;
        left: -0.1vw;
        right: -0.1vw;
        top: -0.1vw;
        background-size: 100% 180px;
        background-position: 50% 0%;
        background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23ffffff"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23ffffff"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23ffffff"/></svg>');
      }
    }
    @media (min-width: 2100px) {
      .shapedividers_com-5429::before {
        background-size: 100% calc(2vw + 180px);
      }
    }
  }
}
