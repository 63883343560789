.about {
  box-shadow: 0px -5px 10px 0 #0d3276;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .container-about {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50px;
    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
      margin-top: 80px;
    }
    .heading-about {
      width: 50%;
      @media (max-width: 768px) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .judul {
        width: 80%;
        font-family: "Poppins", sans-serif;
        font-size: 76px;
        font-weight: 400;
        line-height: 1.1em;
        color: #0d3276;
        @media (max-width: 768px) {
          width: 100%;
          font-size: 30px;
          text-align: center;
        }
        .br {
          width: 80%;
          font-family: "Poppins", sans-serif;
          font-size: 76px;
          font-weight: 700;
          line-height: 1.1em;
          @media (max-width: 768px) {
            width: 100%;
            font-size: 30px;
            text-align: center;
          }
          hr {
            margin-top: -10px;
            width: 100%;
            border: 10px solid #dee9fa;
            justify-self: start;
            @media (max-width: 768px) {
              margin-top: 0px;
              width: 50%;
              border: 3px solid #dee9fa;
              justify-self: center;
            }
          }
        }
      }
      .desk {
        font-size: "Poppins", sans-serif;
        font-size: 15px;
        font-weight: 300;
        line-height: 1.5em;
        @media (max-width: 768px) {
          width: 80%;
          text-align: center;
        }
      }
      .button {
        display: flex;
        margin-top: 20px;
        @media (max-width: 768px) {
          width: 80%;
          justify-content: center;
        }
        .wa {
          font-size: 16px;
          justify-content: center;
          padding: 25px 30px;
          position: absolute;
          z-index: 1;
          cursor: pointer;
          background-color: #0d3276;
          border-width: 1px;
          border-color: #0d3276;
          border-radius: 4px;
          border-style: solid;
          color: white;
          &:hover {
            margin-top: 3px;
            margin-left: 5px;
            padding: 20px 25px;
            background-color: #ffffff;
            border-width: 1px;
            border-color: #0d3276;
            border-radius: 4px;
            border-style: solid;
            color: #0d3276;
            transition: transform 0.3s ease-in-out;
          }
          @media (max-width: 768px) {
            padding: 10px 25px;
          }
        }
      }
    }
    .gambarabout {
      display: flex;
      // justify-content: center;
      position: relative;
      align-items: center;
      width: 50%;
      background-image: url(../../asset/header/about.webp);
      background-size: 90%;
      background-repeat: no-repeat;
      background-position: center;
      height: 120vh;
      @media (max-width: 768px) {
        width: 98%;
        flex-direction: column;
        justify-content: end;
        height: 70vh;
      }
      &:active {
        transform: scale(1.1); /* Adjust scaling factor */
      }
      .fitur-about {
        margin: 74px 0px 0px -14px;
        padding: 25px 35px 35px 35px;
        border-style: solid;
        border-color: #c4cbdb;
        border-width: 1px;
        border-radius: 15px;
        box-shadow: -10px 32px 54px 0px rgba(149, 155, 166, 0.30196078431372547);
        display: flex;
        justify-content: space-between;
        row-gap: 20px;
        flex-direction: column;
        width: 30%;
        background-color: #ffffff;
        &:hover {
          box-shadow: 0px 0px 10px 0px rgba(149, 155, 166, 0.30196078431372547);
          transition: 0.3s;
        }
        @media (max-width: 768px) {
          width: 60%;
          margin: 100px 0px -95px 0px;
          flex-direction: column;
        }
        .logo-navapark {
          width: 100%;
          .logo {
            width: 100%;
            object-fit: cover;
          }
        }
        .judul1 {
          font-family: "Poppins", sans-serif;
          color: #0d3276;
          font-weight: 500;
        }
        .desk1 {
          font-family: "Poppins", sans-serif;
          color: #5a5a5a;
          font-weight: 300;
          font-size: 12px;
        }
        .desk2 {
          margin-top: 15px;
          font-family: "Poppins", sans-serif;
          color: #808080;
          font-weight: 300;
          font-size: 15px;
        }
        .buttoncta {
          .cta {
            font-size: 16px;
            padding: 0px;
            justify-content: center;
            z-index: 1;
            cursor: pointer;
            color: #0d3276;
            background-color: #0d327600;
            border: none;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
